<template>
  <b-row>

    <h2 class="pl-1">{{ $t('roles_permission.titles.roles') }}</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
        md="4"
        class="my-1 px-0"
      >
        <b-form-group class="mb-0">
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="searchQuery"
              type="search"
              :placeholder="$t('reusable.search_placeholder')"
            />
            <b-input-group-append>
              <b-button
                :disabled="!searchQuery"
                @click="searchQuery = ''"
              >
                {{ $t('reusable.clear_btn') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!--   CREATE   -->
      <div
        v-if="createPermission"
        class="my-1 float-right"
      >
        <b-button
          variant="success"
          @click="createRole"
        >
          {{ $t('reusable.create') }}
        </b-button>
      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-card>
        <b-table
          striped
          hover
          responsive
          sort-icon-left
          :busy="isBusy"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >

          <template v-slot:head()="data">
            {{ $t(`${data.label}`) }}
          </template>

          <template #cell(is_admin)="data">
            <span v-if="data.item.is_admin">
              {{ $t('yes') }}
            </span>
            <span v-else>
              {{ $t('no') }}
            </span>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-1">{{ $t('loading') }}</strong>
            </div>
          </template>

          <template #cell(crud_row)="data">
            <div class="d-flex float-right">

              <!--   EDIT   -->

              <modal-button
                v-if="editPermission"
                class="crud__btn"
                ripple="rgba(113, 102, 240, 0.15)"
                size="lg"
                @btn-emit="updateRoleData(data.item.id, data.item)"
                @open-emit="getOneRole(data.item.id)"
                @close-emit="clearInputs"
                :name="`modal-edit-${data.item.id}`"
                :modal-button="$t('reusable.save_btn')"
                :id="data.item.id"
                :modal-title="$t('reusable.modal_edit_btn')"
                variant="flat-warning"
                button-variant="warning"
                ref="update-modal-button"
              >
                <template v-slot:button>
                  <feather-icon
                    @click="$router.push({name:'roles-update', params: {id:data.item.id}})"
                    icon="Edit2Icon"
                    size="21"
                  />
                </template>

              </modal-button>

              <!--   DELETE   -->
              <modal-button
                v-if="deletePermission && !data.item.is_admin"
                ref="delete-modal"
                class="crud__btn"
                ripple="rgba(113, 102, 240, 0.15)"
                size="lg"
                :name="`modal-delete-${data.item.id}`"
                :modal-button="$t('reusable.modal_delete_btn')"
                :modal-title="$t('reusable.modal_delete_btn')"
                :hide-footer="true"
                variant="flat-danger"
                :id="data.item.id"
                @open-emit="clearTransfer"
                @btn-emit="deleteRole"
              >
                <template v-slot:button>
                  <feather-icon
                    icon="Trash2Icon"
                    size="21"
                  />
                </template>

                <template v-slot:modal-body>
                  <div
                    v-if="!transferRole.error"
                    class="my-2"
                  >
                    {{ $t('modal.role_delete') }}
                  </div>
                  <div v-else>
                    <h5 class="mb-4">{{ $t('modal.transfer_role') }}</h5>
                    <div class="row mb-3">
                      <b-col cols="6">
                        <b-form-group :label="$t('roles_permission.title.users')">
                          <div
                            class="mt-1"
                            style=" font-style: italic"
                          >
                            <b
                              v-for="(user,index) in transferRole.users"
                              :key="index"
                            >
                              {{ user }}
                            </b>
                          </div>

                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('roles_permission.titles.roles')"
                          label-for="alias_type"
                        >
                          <b-form-select
                            v-model="transferRole.id"
                            size="lg"
                            :options="transferRole.roles"
                            id="alias_type"
                            :disabled="false"
                            :placeholder="$t('roles_permission.titles.role')"
                          />
                        </b-form-group>
                      </b-col>

                    </div>
                  </div>
                </template>

                <template v-slot:modal-footer>

                  <b-button
                    v-if="!transferRole.error"
                    class="float-right"
                    variant="danger"
                    @click="deleteRole(data.item.id)"
                  >
                    {{ $t('reusable.modal_delete_btn') }}
                  </b-button>
                  <b-button
                    v-else
                    class="float-right"
                    variant="danger"
                    @click="transferDeleteRole(data.item.id)"
                  >
                    {{ $t('reusable.transfer') }}
                  </b-button>

                </template>

              </modal-button>

            </div>
          </template>
        </b-table>
      </b-card>
    </b-col>

    <!--  PAGINATION  -->
    <b-col cols="12">
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="mt-0"
      ></b-pagination>
    </b-col>

  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner,
  BCard,
  BFormTextarea,
} from "bootstrap-vue";
import ModalButton from "@/views/ui/modals/ModalButton";
import api from "@/services/api";
import Ripple from "vue-ripple-directive";
import ToastNotification from "@core/components/toastification/ToastNotification.vue";
import InfinityScrollSelect from "@/views/ui/infinity-scroll/InfinityScrollSelect";
import CardPermission from "@/permission/card";
import RolesPermission from "@/permission/roles";
// import {debounce} from "@/util/helper";

export default {
  name: "Roles",
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
    BCard,
    ModalButton,
    ToastNotification,
    InfinityScrollSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      role: {
        name: null,
        is_admin: false,
      },

      isBusy: false,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      searchQuery: this.$route.query.search,
      filterOn: [],
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "name",
          label: "name",
        },
        {
          key: "is_admin",
          label: "roles_permission.is_admin",
        },
        {
          key: "crud_row",
          label: "",
        },
      ],
      items: [],
      loading: false,
      transferRole: {
        roles: null,
        error: false,
        users: null,
        id: null,
      },
      pagination: {
        current: 1,
        perPage: 20,
      },
      createPermission: RolesPermission.getRolesCreatePermission(),
      editPermission: RolesPermission.getRolesEditPermission(),
      deletePermission: RolesPermission.getRolesDeletePermission(),
    };
  },
  watch: {
    "$route.query": {
      handler(query) {
        this.pagination.current_page = query.page;
        this.getRoleList();
      },
      deep: true,
    },
    "pagination.current": {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        });
      },
    },
    searchQuery: {
      handler(value) {
        if (value && value.length > 2) {
          this.replaceRouter({
            ...this.query,
            search: value,
          });
        } else {
          const hasSearchField = this.query.hasOwnProperty("search");
          if (hasSearchField) {
            delete this.query["search"];
            this.replaceRouter({
              ...this.query,
            });
          }
        }
      },
    },
  },

  async created() {
    await this.getRoleList();
  },

  computed: {
    query() {
      return Object.assign({}, this.$route.query);
    },

    hasItems() {
      return this.items.length > 0;
    },

    showPagination() {
      return this.hasItems && !this.isBusy;
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
  },

  methods: {
    showToast(variant, text, icon) {
      this.$toast({
        component: ToastNotification,
        props: {
          title: text,
          icon: icon,
          variant,
        },
      });
    },

    replaceRouter(query) {
      this.$router.replace({ query }).catch(() => {});
    },

    clearInputs() {
      this.role = {
        name: null,
        is_admin: 0,
      };
    },

    async getRoleList() {
      this.isBusy = true;
      await api.role
        .getRoleList(this.query)
        .then((res) => {
          this.items = res.data.data.data;
          this.pagination = res.data.data;
        })
        .catch(() => {
          this.showToast("danger", "Что-то пошло не так!", "XIcon");
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    getOneRole(id) {
      this.loading = true;
      api.role
        .getRole(id)
        .then((res) => {
          this.role = res.data;
          this.role.is_admin = !!res.data.is_admin;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createRole() {
      this.$router.push({ name: "role-create" });
    },

    async updateRoleData(id) {
      const isValid = await this.$refs["validation-observer"].validate();
      if (isValid) {
        const data = this.role;
        api.role
          .updateRole(id, data)
          .then(() => {
            this.showToast("success", "Успешно обновлено!", "CheckIcon");
            this.$refs["update-modal-button"].closeModal();
            this.getRoleList();
            this.clearInputs();
          })
          .catch((error) => {
            if (error.response.data && error.response.data.errors) {
              let errors = [];
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                errors.push(value[0]);
              }
              errors.join(", ");
              this.showToast("danger", errors[0], "XIcon");
            } else if (error.response.data && error.response.data.message) {
              this.showToast("danger", error.response.data.message, "XIcon");
            } else {
              this.showToast("danger", "Что-то пошло не так!", "XIcon");
            }
          });
      }
    },
    clearTransfer() {
      this.transferRole = {
        roles: null,
        error: false,
        users: null,
        id: null,
      };
    },
    async deleteRole(id) {
      await api.role
        .deleteRole(id)
        .then(() => {
          this.$refs["delete-modal"].closeModal();
          this.showToast("success", "Успешно удалено!", "CheckIcon");
          this.getRoleList();
        })
        .catch((err) => {
          if (err.response.status === 406) {
            const roleList = this.items.map((item) => {
              return {
                value: item.id,
                text: item.name,
              };
            });
            this.transferRole = {
              roles: roleList,
              error: true,
              users: err.response.data.data.join(", ").split(" "),
            };
          } else {
            this.$refs["delete-modal"].closeModal();
            this.showToast("danger", "Что-то пошло не так!", "XIcon");
          }
        })
        .finally(() => {
          // this.getRoleList()
        });
    },

    transferDeleteRole(id) {
      const data = {
        new_role: this.transferRole.id,
      };
      api.role
        .transferRole(id, data)
        .then(() => {
          this.transferRole = {
            roles: null,
            error: false,
            users: null,
          };
          this.$refs["delete-modal"].closeModal();
          this.clearTransfer();
        })
        .catch((error) => {
          if (error.response.data && error.response.data.errors) {
            let errors = [];
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              errors.push(value[0]);
            }
            errors.join(", ");
            this.showToast("danger", errors[0], "XIcon");
          } else if (error.response.data && error.response.data.message) {
            this.showToast("danger", error.response.data.message, "XIcon");
          } else {
            this.showToast("danger", "Что-то пошло не так!", "XIcon");
          }
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss">
[dir] .dropdown-item {
  padding: 0;
}

.validation__red {
  color: red;
  font-size: 12px;
  display: block;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 6px;
}

.crud__btn .btn {
  padding: 0.6rem;
}
</style>
